html {
  overflow-y: scroll;
}

.block {
  width: 100%;
  height: 100%;
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23AEAEAEFF' stroke-width='3' stroke-dasharray='2%25%2c4%25' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.027);
}

body {
  background-color: var(--cui-tertiary-bg);
}

.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  @include ltr-rtl('padding-right', var(--cui-sidebar-occupy-end, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.header > .container-fluid,
.sidebar-header {
  min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
}

.sidebar-brand-full {
  margin-left: 0px;
  --cui-sidebar-header-padding-y: 100px;
}

.selected-box {
}

.selected-box-pusher {
  display: inline-block;
  width: 50;
}

@keyframes swoop {
  0% {
    margin-left: 0%;
  }

  100% {
    margin-left: -130%;
  }
}

.dropdown-item {
  opacity: 1;
}

.dropdown-item:hover {
  background-color: #272a2d;
}

.dropdown-item:hover .dropdown-icon {
  opacity: 1;
}

.dropdown-icon {
  opacity: 0;
}

.swoop-notification {
  animation: swoop 1s;
}

.sidebar-header {
  .nav-underline-border {
    --cui-nav-underline-border-link-padding-x: 1rem;
    --cui-nav-underline-border-gap: 0;
  }

  .nav-link {
    display: flex;
    align-items: center;
    min-height: calc(4rem + 1px); // stylelint-disable-line function-disallowed-list
  }
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-right {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 100%;
  -ms-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}

.sidebar-toggler {
  @include ltr-rtl('margin-left', auto);
}

.sidebar-narrow,
.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-toggler {
    @include ltr-rtl('margin-right', auto);
  }
}

.header > .container-fluid + .container-fluid {
  min-height: 3rem;
}

.footer {
  min-height: calc(3rem + 1px); // stylelint-disable-line function-disallowed-list
}

.search {
  background-color: $input-bg-search;
}

.form-control:focus {
  background-color: $input-bg-search;
  //box-shadow:     0 0 3px 0px #000000,  /* inner white */
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.text-highlight {
  border: solid;
  border-color: $fresh-red;
  transition: 0s;
  border-width: 2px;
  opacity: 1;
}

.text {
  border: solid;
  border-color: $fresh-red;
  transition: 0s;
  border-width: 2px;
  opacity: 0;
}

.visible {
  transition: max-height 0s linear;
  max-height: 500px;
  overflow: hidden;
}

.hidden {
  transition: max-height 0s linear;
  max-height: 0;
  overflow: hidden;
}

.triangle-showing {
  rotate: 180deg;
  transition: rotate 0.1s;
}

.triangle-hiding {
  rotate: 90deg;
  transition: rotate 0.1s;
}

.text:hover {
  opacity: 1;
  //background-color: firebrick;
  transition: 0.1s;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.calendar-button {
  background-color: #373a3d;
}

.calendar-button:hover {
  background-color: mix(#373a3d, #888);
}

.calendar-button:disabled {
  background-color: #373a3d;
}

.highlight-first {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.highlight-last {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.highlight-first-selected {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.highlight-last-selected {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.bg-subtle-primary:hover {
  background-color: rgba(164, 31, 19, 0.4);
}

.bg-primary-25 {
  background-color: rgba(164, 31, 19, 0.1);
}

.glow-effect {
  -webkit-box-shadow: 0px 0px 71px 12px rgba(255, 46, 46, 0.21);
  -moz-box-shadow: 0px 0px 71px 12px rgba(255, 46, 46, 0.21);
  box-shadow: 0px 0px 30px 3px rgba(255, 46, 46, 0.21);
  transition: box-shadow 0.1s;
}

.glow-effect:hover {
  -webkit-box-shadow: 0px 0px 71px 12px rgba(255, 46, 46, 0.21);
  -moz-box-shadow: 0px 0px 71px 12px rgba(255, 46, 46, 0.21);
  box-shadow: 0px 0px 71px 12px rgba(255, 46, 46, 0.21);
  transition: box-shadow 0.1s;
}

.upload:hover {
  transform: scale(1.03);
  transition: transform 0.1s;
}

.upload {
  transform: scale(1);
  transition: transform 0.1s;
}

.hovercard {
  outline: none;
  transform: scale(1);
  transition: transform 0.1s;
}

.hovercard:hover {
  transform: scale(1.01);
  transition: transform 0.1s;
}

.meta-hidden {
  max-height: 0;
  transition: max-height 1s;
}

.meta-visible {
  max-height: fit-content;
  transition: max-height 1s;
}

.notification-cross {
  display: none;
  cursor: pointer;
}

.notification-box {
  background-color: $dark;
}

.notification-box:hover .notification-cross {
  display: block;
}

.notification-box:hover {
  scale: 1.01;
  background-color: #52100a;
}

.project-tile {
  background-color:rgba(255, 255, 255, 0.027);
  transition: background-color 0.3s linear;
}

.project-tile:hover {
  //scale: 1.05;
  background-color: #9b271c;
}

.wave {
  --size: 20px;
  --m: 0.5;
  --b: 2px;
  --p: calc(var(--m) * var(--size));
  --R: calc(var(--size) * sqrt(var(--m) * var(--m) + 1) + var(--b) / 2);

  //animation: animWave 1s alternate infinite;

  --_g: #0000 calc(99% - var(--b)), #000 calc(101% - var(--b)) 99%, #0000 101%;
  mask:
    radial-gradient(var(--R) at left 50% bottom calc(-1 * var(--p)), var(--_g))
      calc(50% - 2 * var(--size)) calc(50% - var(--size) / 2 - var(--b) / 2) / calc(4 * var(--size))
      calc(var(--size) + var(--b)) repeat-x,
    radial-gradient(var(--R) at left 50% top calc(-1 * var(--p)), var(--_g)) 50%
      calc(50% + var(--size) / 2 + var(--b) / 2) / calc(4 * var(--size))
      calc(var(--size) + var(--b)) repeat-x;
}

@keyframes animWave {
  0% {
    --m: 0;
  }

  50% {
    --m: 0.5;
  }

  100% {
    --m: 1;
  }
}

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow:
    483px 450px #fff,
    1995px 525px #fff,
    755px 1459px #fff,
    610px 1246px #fff,
    764px 1850px #fff,
    1274px 1697px #fff,
    1227px 37px #fff,
    1090px 506px #fff,
    341px 1005px #fff,
    1002px 755px #fff,
    693px 1497px #fff,
    1797px 1626px #fff,
    1838px 793px #fff,
    802px 881px #fff,
    1117px 1983px #fff,
    352px 1465px #fff,
    1526px 852px #fff,
    1559px 526px #fff,
    517px 516px #fff,
    1380px 244px #fff,
    662px 1531px #fff,
    1544px 518px #fff,
    253px 879px #fff,
    774px 1629px #fff,
    752px 994px #fff,
    1129px 726px #fff,
    232px 1050px #fff,
    1766px 290px #fff,
    1976px 842px #fff,
    662px 1535px #fff,
    257px 1016px #fff,
    665px 708px #fff,
    1476px 1772px #fff,
    261px 498px #fff,
    719px 1459px #fff,
    469px 438px #fff,
    994px 474px #fff,
    744px 808px #fff,
    1609px 1741px #fff,
    898px 260px #fff,
    1978px 1436px #fff,
    904px 1244px #fff,
    123px 773px #fff,
    6px 923px #fff,
    1973px 1778px #fff,
    1470px 1861px #fff,
    1037px 156px #fff,
    554px 1905px #fff,
    1508px 849px #fff,
    1213px 1603px #fff,
    87px 1907px #fff,
    492px 527px #fff,
    91px 213px #fff,
    492px 15px #fff,
    1357px 1157px #fff,
    637px 240px #fff,
    1515px 281px #fff,
    1757px 682px #fff,
    1035px 1298px #fff,
    1019px 1233px #fff,
    25px 162px #fff,
    262px 884px #fff,
    1328px 965px #fff,
    984px 879px #fff,
    1213px 689px #fff,
    623px 1091px #fff,
    1227px 422px #fff,
    1977px 1025px #fff,
    620px 474px #fff,
    528px 1525px #fff;
  animation: animStar 10s linear infinite;
}

#stars:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow:
    483px 450px #fff,
    1995px 525px #fff,
    755px 1459px #fff,
    610px 1246px #fff,
    764px 1850px #fff,
    1274px 1697px #fff,
    1227px 37px #fff,
    1090px 506px #fff,
    341px 1005px #fff,
    1002px 755px #fff,
    693px 1497px #fff,
    1797px 1626px #fff,
    1838px 793px #fff,
    802px 881px #fff,
    1117px 1983px #fff,
    352px 1465px #fff,
    1526px 852px #fff,
    1559px 526px #fff,
    517px 516px #fff,
    1380px 244px #fff,
    662px 1531px #fff,
    1544px 518px #fff,
    253px 879px #fff,
    774px 1629px #fff,
    752px 994px #fff,
    1129px 726px #fff,
    232px 1050px #fff,
    1766px 290px #fff,
    1976px 842px #fff,
    662px 1535px #fff,
    257px 1016px #fff,
    665px 708px #fff,
    1476px 1772px #fff,
    261px 498px #fff,
    719px 1459px #fff,
    469px 438px #fff,
    994px 474px #fff,
    744px 808px #fff,
    1609px 1741px #fff,
    898px 260px #fff,
    1978px 1436px #fff,
    904px 1244px #fff,
    123px 773px #fff,
    6px 923px #fff,
    1973px 1778px #fff,
    1470px 1861px #fff,
    1037px 156px #fff,
    554px 1905px #fff,
    1508px 849px #fff,
    1213px 1603px #fff,
    87px 1907px #fff,
    492px 527px #fff,
    91px 213px #fff,
    492px 15px #fff,
    1357px 1157px #fff,
    637px 240px #fff,
    1515px 281px #fff,
    1757px 682px #fff,
    1035px 1298px #fff,
    1019px 1233px #fff,
    25px 162px #fff,
    262px 884px #fff,
    1328px 965px #fff,
    984px 879px #fff,
    1213px 689px #fff,
    623px 1091px #fff,
    1227px 422px #fff,
    1977px 1025px #fff,
    620px 474px #fff,
    528px 1525px #fff;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow:
    1585px 315px #fff,
    1750px 858px #fff,
    502px 1184px #fff,
    1482px 150px #fff,
    870px 93px #fff,
    1401px 1976px #fff,
    494px 1549px #fff,
    1607px 1894px #fff,
    1029px 1466px #fff,
    1931px 1390px #fff,
    819px 1431px #fff,
    521px 1062px #fff,
    1754px 574px #fff,
    577px 943px #fff,
    850px 1377px #fff,
    445px 1835px #fff,
    1658px 1675px #fff,
    521px 1144px #fff,
    560px 1624px #fff,
    1329px 1778px #fff;
  animation: animStar 20s linear infinite;
}

#stars2:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow:
    1585px 315px #fff,
    1750px 858px #fff,
    502px 1184px #fff,
    1482px 150px #fff,
    870px 93px #fff,
    1401px 1976px #fff,
    494px 1549px #fff,
    1607px 1894px #fff,
    1029px 1466px #fff,
    1931px 1390px #fff,
    819px 1431px #fff,
    521px 1062px #fff,
    1754px 574px #fff,
    577px 943px #fff,
    850px 1377px #fff,
    445px 1835px #fff,
    1658px 1675px #fff,
    521px 1144px #fff,
    560px 1624px #fff,
    1329px 1778px #fff;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow:
    91px 409px #fff,
    1886px 728px #fff,
    1294px 970px #fff,
    720px 1215px #fff,
    796px 1431px #fff,
    834px 1217px #fff,
    1926px 1164px #fff,
    1803px 310px #fff,
    1532px 1421px #fff,
    1680px 232px #fff;
  animation: animStar 30s linear infinite;
}

#stars3:after {
  content: ' ';
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow:
    91px 409px #fff,
    1886px 728px #fff,
    1294px 970px #fff,
    720px 1215px #fff,
    796px 1431px #fff,
    834px 1217px #fff,
    1926px 1164px #fff,
    1803px 310px #fff,
    1532px 1421px #fff,
    1680px 232px #fff;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.popup-warning {
  margin-top: -40px;
  transition: margin-top 0.1s linear;
}

.popup-warning-showing {
  margin-top: -10px;
  transition: margin-top 0.1s linear;
}

.sidebar-button {
  margin-left: 0px;
  transition: margin-left 0s linear;
}

.sidebar-button:hover {
  margin-left: 5px;
}

.app-tile {
  background-color: #2e3135;
  width: 85px;
  height: 85px;
  transition: background-color 0.05s linear;
}

.app-tile:hover {
  background-color: #52100a;
  scale: 1.05;
}

.graph-tile {
  background-color: #52100a;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(-2000px);
  }
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(15px);
  }

  50% {
    transform: translateX(-15px);
  }

  75% {
    transform: translateX(15px);
  }

  100% {
    transform: translateX(0);
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    body {
      background-color: var(--cui-dark-bg-subtle);
    }

    .header {
      background-color: $header-bg-dark;
    }

    .footer {
      --cui-footer-bg: var(--cui-body-bg);
    }
  }
}
