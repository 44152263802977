// Variable overrides
//
// If you want to customize your project please add your variables below.

$enable-deprecation-messages: false !default;
$enable-dark-mode: true;

$fresh-red: #a41f13;
$white-fog: #faf5f1;
$light-gray: #e0dbd8;
$carbon-gray: #292f36;
$soft-brown: #8f7a6e;

//
//
//
$primary: $fresh-red;
$secondary: #9da5b1;
$success: #2eb85c;
$info: $fresh-red;
$warning: rgb(251, 205, 0);
$danger: #ff9900;
$light: #646464;
$dark: mix($carbon-gray, #333);

/*
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
);
*/

$theme-colors-dark: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
);

//$body-bg: #000;
//$body-color: #111;
//$body-bg:           $dark;
//$body-color-dark:   $dark;
//$body-bg-dark:      #141414;
//$body-secondary-bg-dark: #fff;
//$body-secondary-color-dark: #fff;
//$body-tertiary-bg-dark: #fff;
//$body-tertiary-color-dark: #fff;

$header-bg-dark: mix($carbon-gray, #444); //mix($soft-brown, #000000);
$body-bg-dark: mix($carbon-gray, #444);
$dark-bg-subtle-dark: mix($carbon-gray, #333);
$body-tertiary-bg-dark: mix($fresh-red, #000000);
//$border-color-dark: $white-fog;

$input-bg: mix($carbon-gray, #666);
$input-bg-search: mix($carbon-gray, #444);
$input-bg-search-focus: rgba(mix($fresh-red, #444), 0.3);
$input-border-color: #202020;
$input-disabled-border-color: rgba(0, 0, 0, 0);
$input-disabled-bg: mix($carbon-gray, #333);

$input-padding: 100;
$input-padding-x-sm: 100;
$input-padding-x-lg: 100;
$input-font-size: 100;

//$table-bg: #000;
$table-color-state: #000;

//$modal-backdrop-filter: blur(10px);
